import NextLink from 'next/link';

import { ContentfulImage, Icon } from '@atoms';
import { ArrowRightLine } from '@components/icons/System';

const ImageCardLink = ({
  href,
  imageAlt,
  imageSrc,
  title
}: {
  href: string;
  imageAlt: string;
  imageSrc: string;
  title: string;
}) => (
  <NextLink passHref href={href} target="_blank">
    <div className="flex flex-row gap-4 p-2">
      <div className="relative w-[51px] h-[55px] overflow-hidden">
        <ContentfulImage
          className="w-full rounded-xl"
          src={imageSrc}
          alt={imageAlt}
          layout="responsive"
          objectFit="cover"
          aria-hidden
          width={102}
          height={110}
          sizes="33vw"
        />
      </div>
      <div className="flex flex-row justify-between flex-1 gap-6">
        <h5>{title}</h5>
        <Icon icon={ArrowRightLine} />
      </div>
    </div>
  </NextLink>
);

export default ImageCardLink;
