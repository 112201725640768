import NextLink from 'next/link';

import { ContentfulImage, Icon } from '@atoms';
import { useLocale, useMediaQuery } from '@hooks';
import { breakpoints } from '@utils';
import { ArrowRightLine } from '@components/icons/System';

const ImageCard = ({
  slug,
  imageUrl,
  alt,
  title,
  subTitle
}: {
  slug: string;
  imageUrl?: string;
  alt: string;
  title: string;
  subTitle: string;
}) => {
  const locale = useLocale();
  const isTablet = useMediaQuery(breakpoints.tablet);

  return (
    <NextLink
      locale={locale}
      href={slug}
      prefetch={false}
      className="hover:text-white group"
    >
      <div className="p-2 -m-2 hover:cursor-pointer group-hover:bg-hx-dark-card-hover">
        <div className="relative flex overflow-hidden w-full h-[35vw] tablet:h-[369px]">
          <ContentfulImage
            src={imageUrl}
            layout="fill"
            objectFit="cover"
            alt={alt}
          />
        </div>

        <h3 className="pt-2 h5-text group-hover:underline">{title}</h3>
        {isTablet && (
          <div className="flex gap-4 mt-1">
            <p className="no-underline">{subTitle}</p>
            <Icon icon={ArrowRightLine} size="2x" />
          </div>
        )}
      </div>
    </NextLink>
  );
};

export default ImageCard;
